/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import "./login.scss"
import { BallBeat } from '../../Loader/loader';
import OtpInput from '../../utils/otpInput';
import Snackbar from '@mui/material/Snackbar';
import { masterAdminLogin, instituteLogin, userLogin, autoLogin, autoAdminLogin, acceptInvite, forgotPassword, resetPasswords } from '../../Redux/ActionCreators/authAction';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import i18n from '../../i18n';
import Lottie from "react-lottie";
import Loading from '../../assets/hour-glass.json';


function Login() {
    const [defaultShowSignUp, setDefaultShowSignUp] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isResetPassword, setISResetPassword] = useState(false);
    const [resetPasswordComplete, setResetPasswordComplete] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [otpEmail, setOtpEmail] = useState('');
    const [isOtpEmailLoading, setIsOtpEmailLoading] = useState(false);
    const [otpEnable, setOtpEnable] = useState(false);
    const [emailOtpScreen, setEmailOtpScreen] = useState(true);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [successEnable, setSuccessEnable] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [responseStatus, setResponseStatus] = useState('');
    const [lang, setLang] = useState('');
    const [type, setType] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { logoDetails } = useSelector(state => state.auth);
    const error = useSelector(state => state.error);


    useEffect(() => {
        // document.title = logoDetails?.name
        if (window.location.href.includes("auto")) {
            checkUserAuth();
        }
        if (window.location.href.includes("code")) {
            checkAdminAuth();
        }
        // if (window.location.href.includes('auto')) {
        //     setOtp(window.location.search.split("=")[1]);
        // }
        if (window.location.href.includes('otp')) {
            setDefaultShowSignUp(false);
            setOtp(window.location.search.split("=")[1]);
        }


        if (localStorage.getItem('role') === 'student') {
            if (localStorage.getItem('loginToken') !== null) {
                (window.location.pathname === '/' || window.location.pathname === '/login' || window.location.pathname === '/signup') &&
                    navigate('/my-learning')
            }
            else {
                (window.location.pathname === '/' || window.location.pathname === '/login') &&
                    navigate('/login')
            }
        }
        else if ((localStorage.getItem('role') !== null && ((localStorage.getItem('role') === 'super-admin') || (localStorage.getItem('role') === 'master-admin')))) {
            if (localStorage.getItem('loginToken') !== null) {

                (window.location.pathname === '/' || window.location.pathname === '/login') &&
                    navigate('/reports')
            }
            else {
                (window.location.pathname === '/' || window.location.pathname === '/login') &&
                    navigate('/login')
            }
        }
        else if ((localStorage.getItem('role') !== null && (localStorage.getItem('role').includes('faculty') || localStorage.getItem('role').includes('coordinator') || localStorage.getItem('role').includes('counsellor')))) {
            if (localStorage.getItem('loginToken') !== null) {

                (window.location.pathname === '/' || window.location.pathname === '/login') &&
                    navigate('/my-subjects')
            }
            else {
                (window.location.pathname === '/' || window.location.pathname === '/login') &&
                    navigate('/login')
            }
        }
        else {
            if (localStorage.getItem('loginToken') !== null) {

                (window.location.pathname === '/' || window.location.pathname === '/login') &&
                    navigate('/manage-content')
            }
            else {
                (window.location.pathname === '/' || window.location.pathname === '/login') &&
                    navigate('/login')
            }
        }

    }, [])

    const checkUserAuth = async () => {
        if (window.location.href.includes("auto")) {
            setShowLoading(true);
            const code = window.location.href.split("=")[1];
            await dispatch(autoLogin(code));
            setTimeout(() => {
                if (error.successMessage === true) {
                    setShowLoading(false);
                }
            }, 1000);
        }
    }

    const checkAdminAuth = async () => {
        if (window.location.href.includes("code")) {
            setShowLoading(true);
            const code = window.location.href.split("=")[1];
            await dispatch(autoAdminLogin(code));
            setTimeout(() => {
                if (error.successMessage === true) {
                    setShowLoading(false);
                }
            }, 1000);
        }
    }

    const onEmailChange = (e) => {
        setEmailError('');
        setEmail(e.target.value);
    }

    const onChangePassword = (e) => {
        setPasswordError('');
        setPassword(e.target.value);
    }

    const validateInput = (value) => {
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const idRegex = /^[a-zA-Z0-9]+$/; // Adjust this regex to fit your ID validation criteria

        return emailRegex.test(value) || idRegex.test(value);
    };



    const loginUser = async () => {
        setIsLoading(true);
        setResponseStatus('')
        let emailvalue = email;
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // eslint-disable-next-line no-useless-escape
        if (!emailvalue) {
            setEmailError(`Email can't be blank`);
            setIsLoading(false);
            return false;
        }
        else if (!validateInput(emailvalue)) {
            setEmailError("Email/Student Id is not valid");
            setIsLoading(false);
            return false;
        }
        else if (password === "") {
            setPasswordError('Please enter password');
            setIsLoading(false);
        }
        else {
            let login = emailRegex.test(email) ?
                {
                    _id: logoDetails?._id,
                    email: email,
                    password: password,
                    type: logoDetails?.type
                } :
                {
                    _id: logoDetails?._id,
                    id_num: email,
                    password: password,
                    type: logoDetails?.type
                }
            const lng = (lang === 'English' ? 'en' : lang === 'Kannada' ? 'kn' : lang === 'Tamil' ? 'tn' : null);
            logoDetails?.type === 'masteradmin' ? await dispatch(masterAdminLogin(login, lang)) : logoDetails?.type === 'institution' ? await dispatch(instituteLogin(login, lng)) : await dispatch(userLogin(login, lng));
            localStorage.getItem('loginToken') !== null ?
                navigate('/my-learning')
                : navigate('/login')
            setTimeout(() => {
                if (error.successMessage === false) {
                    setIsLoading(false);
                }
            }, 1000);

        }
    }

    const loginOnKeyPress = async (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            setIsLoading(true);
            setResponseStatus('')
            let emailvalue = email;
            // eslint-disable-next-line no-useless-escape
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!emailvalue) {
                setEmailError(`Email can't be blank`);
                setIsLoading(false);
                return false;
            }
            else if (!validateInput(emailvalue)) {
                setEmailError("Email/Student Id is not valid");
                setIsLoading(false);
                return false;
            }
            else if (password === "") {
                setPasswordError('Please enter password');
                setIsLoading(false);
            }
            else {
                let login = emailRegex.test(email) ?
                    {
                        _id: logoDetails?._id,
                        email: email,
                        password: password,
                        type: logoDetails?.type
                    } :
                    {
                        _id: logoDetails?._id,
                        id_num: email,
                        password: password,
                        type: logoDetails?.type
                    }
                const lng = lang === 'English' ? 'en' : lang === 'Kannada' ? 'kn' : lang === 'Tamil' ? 'tn' : 'en';
                logoDetails?.type === 'masteradmin' ? await dispatch(masterAdminLogin(login, lang)) : logoDetails?.type === 'institution' ? await dispatch(instituteLogin(login, lng)) : await dispatch(userLogin(login, lng));
                localStorage.getItem('loginToken') !== null ?
                    navigate('/my-learning')
                    : navigate('/login')
                setTimeout(() => {
                    if (error.successMessage === false) {
                        setIsLoading(false);
                    }
                }, 1000);

            }
        }
    }

    const resetPassword = () => {
        setISResetPassword(true);
        setEmailError('');
    }

    const handleRadio = (e) => {
        console.log(e)
        setType(e);
        setOtpEmail('');
        setEmailError('');
    }

    const handleChange = otp => setOtp(otp);

    const onOtpEmailChange = (e) => {
        setEmailError('');
        setOtpEmail(e.target.value);
    }

    const handleOtpEmail = async () => {
        // eslint-disable-next-line no-useless-escape
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let obj
        if (type === 'student') {
            if (!otpEmail) {
                setEmailError(`StudentId can't be blank`);
                return false;
            }
            else if (emailrex.test(String(otpEmail))) {
                setEmailError("StudentId is not valid");
                return false;
            }
            else {
                await setIsOtpEmailLoading(true);
                obj = {
                    role: 'student',
                    student_id: otpEmail
                }
                const res = await dispatch(forgotPassword(obj));
                if (res?.type === 'Forgot_Password') {
                    await setOtpEnable(true);
                    await setEmailOtpScreen(false);
                }
                await setIsOtpEmailLoading(false);
            }
        } else {
            if (!otpEmail) {
                setEmailError('Email cant be blank');
                return false;
            }
            else if (!emailrex.test(String(otpEmail))) {
                setEmailError("Email is not valid");
                return false;
            }
            else {
                await setIsOtpEmailLoading(true);
                obj = {
                    role: 'staff',
                    email: otpEmail
                }
                const res = await dispatch(forgotPassword(obj));
                if (res?.type === 'Forgot_Password') {
                    await setOtpEnable(true);
                    await setEmailOtpScreen(false);
                }
                await setIsOtpEmailLoading(false);
            }
        }
    }


    const onOtpEmailChangeKeyPress = async (e) => {
        // eslint-disable-next-line no-restricted-globals
        if (e.which === 13 || event.keyCode === 13) {
            // eslint-disable-next-line no-useless-escape
            let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let obj
            if (type === 'student') {
                if (!otpEmail) {
                    setEmailError(`StudentId can't be blank`);
                    return false;
                }
                else if (emailrex.test(String(otpEmail))) {
                    setEmailError("Student Id is not valid");
                    return false;
                }
                else {
                    await setIsOtpEmailLoading(true);
                    obj = {
                        role: 'student',
                        student_id: otpEmail
                    }
                    const res = await dispatch(forgotPassword(obj));
                    if (res?.type === 'Forgot_Password') {
                        await setOtpEnable(true);
                        await setEmailOtpScreen(false);
                    }
                    await setIsOtpEmailLoading(false);
                }
            } else {
                if (!otpEmail) {
                    setEmailError('Email cant be blank');
                    return false;
                }
                else if (!emailrex.test(String(otpEmail))) {
                    setEmailError("Email is not valid");
                    return false;
                }
                else {
                    await setIsOtpEmailLoading(true);
                    obj = {
                        role: 'staff',
                        email: otpEmail
                    }
                    const res = await dispatch(forgotPassword(obj));
                    if (res?.type === 'Forgot_Password') {
                        await setOtpEnable(true);
                        await setEmailOtpScreen(false);
                    }
                    await setIsOtpEmailLoading(false);
                }
            }
        }
    }

    const onPasswordChange = async (e) => {
        // eslint-disable-next-line no-restricted-globals
        if (e.which === 13 || event.keyCode === 13) {
            if (newPassword === "") {
                setSuccessEnable(true);
                setSuccessMessageText("Please enter new password");
            }
            else if (confirmNewPassword === "") {
                setSuccessEnable(true);
                setSuccessMessageText("Please enter confirm password");
            }
            else if (newPassword !== confirmNewPassword) {
                setSuccessEnable(true);
                setSuccessMessageText("Password did not match");
            }
            else {
                setIsOtpEmailLoading(true);
                let obj = {
                    otp: otp,
                    password: newPassword
                }
                await dispatch(acceptInvite(obj));
                // await props.isAuthorized && localStorage.getItem('logintoken') !== null ?
                //     navigate('/user-dashboard')
                //     : navigate('/login')
                // await setIsOtpEmailLoading(false);
            }
        }
    }

    const handleOtpCheck = async () => {
        if (newPassword === "") {
            setSuccessEnable(true);
            setSuccessMessageText("Please enter new password");
        }
        else if (confirmNewPassword === "") {
            setSuccessEnable(true);
            setSuccessMessageText("Please enter confirm password");
        }
        else if (newPassword !== confirmNewPassword) {
            setSuccessEnable(true);
            setSuccessMessageText("Password did not match");
        }

        else {
            await setIsOtpEmailLoading(true);
            let obj = {
                otp: otp,
                password: newPassword
            }
            await dispatch(acceptInvite(obj));
            // props.isAuthorized && localStorage.getItem('logintoken') !== null ?
            //     navigate('/my-learning')
            //     : navigate('/login')

            await setIsOtpEmailLoading(false);
        }
    }

    const handleOtpCheck1 = async () => {
        if (newPassword === "") {
            setSuccessEnable(true);
            setSuccessMessageText("Please enter new password");
        }
        else if (confirmNewPassword === "") {
            setSuccessEnable(true);
            setSuccessMessageText("Please enter confirm password");
        }
        else if (newPassword !== confirmNewPassword) {
            setSuccessEnable(true);
            setSuccessMessageText("Password did not match");
        }
        else if (otp.length < 6) {
            setSuccessEnable(true);
            setSuccessMessageText("Please enter all the code fields");
        }
        else {
            setIsOtpEmailLoading(true);

            let obj = {
                otp: otp,
                password: newPassword
            }
            await dispatch(resetPasswords(obj));
            await setResetPasswordComplete(true);
            await setIsOtpEmailLoading(false);
        }
    }
    const redirectTologin = () => {
        window.location.reload()
    }




    const snackClosed = () => {
        dispatch(clearErrors());
        setSuccessEnable(false);
        setSuccessMessageText('')
    }


    const changeLang = (e) => {
        setLang(e);
        if (e === 'English') {
            i18n.changeLanguage('en');
            localStorage.setItem("i18nextLang", 'en');
        }
        else if (e === 'Kannada') {
            i18n.changeLanguage('kn');
            localStorage.setItem("i18nextLang", 'kn');
        }
        else if (e === 'Tamil') {
            i18n.changeLanguage('tn');
            localStorage.setItem("i18nextLang", 'tn');
        }
        else if (e === 'Oria') {
            i18n.changeLanguage('od');
            localStorage.setItem("i18nextLang", 'od');
        }
    };


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Loading,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={error.successMessage}
                onClose={() => snackClosed()}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {error.successMessageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={error.successEnable}
                onClose={() => snackClosed()}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {error.successMessageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successEnable}
                onClose={() => snackClosed()}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            {showLoading ?
                <div style={{ position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                    <Lottie options={defaultOptions} width='400px' height='400px' />
                    <p className="text-center">Loading please wait...</p>
                </div>
                :

                <div style={{ height: '100vh', width: '100%', backgroundColor: '#fff', background: window.innerWidth < 767 ? `url('${logoDetails?.images[0]}') no-repeat center center fixed` : null }} >
                    {window.innerWidth > 767 &&
                        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">

                            <div className="carousel-inner" style={{ height: '100vh' }} >
                                {
                                    logoDetails?.images?.map((el, index) => {
                                        return (
                                            <div key={el.id} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                                <img src={el} className="d-block w-100" style={{ height: '100vh' }} alt="..." />
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>}



                    <div className='col-md-12  bg-light text-center' >
                        {defaultShowSignUp ?
                            <div className='login-holder'>

                                {
                                    isResetPassword === false ?
                                        <Fragment>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <div style={{ backgroundImage: `url('${logoDetails?.logo}')`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', padding: 30, backgroundSize: 'contain', width: 160, }}>

                                                </div>
                                            </div>
                                            {/* <img src={logoDetails?.logo} alt='' height='70px' /> */}
                                            <div style={{ marginTop: 30, width: '100%' }}>
                                                {
                                                    <span style={{ fontWeight: 600, color: 'red', fontSize: 14, paddingTop: '5px', position: 'relative', marginBottom: -10 }}>{responseStatus}</span>
                                                }
                                                <div className="form-group mt-2">
                                                    <div className="has-search"  >
                                                        <span className="form-control-feedback" style={{ top: 0 }}>
                                                            <img src="/images/email-icon.svg" alt='' />
                                                        </span>
                                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Student Id" value={email} onChange={(e) => onEmailChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                                    </div>
                                                    <div className='error'>{emailError}</div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="has-search"  >
                                                        <span className="form-control-feedback" style={{ top: 0 }} >
                                                            <img src="/images/password-icon.svg" alt='' />
                                                        </span>
                                                        <input type="password" className="form-control" aria-describedby="emailHelp" value={password} placeholder="Password" onChange={(e) => onChangePassword(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                                    </div>
                                                    <div className='error'>{passwordError}</div>
                                                </div>
                                                {/* {
                                                    logoDetails?.type === 'school' ?
                                                        <div className="form-group text-left">
                                                            <div className="elite-lang-select">
                                                                {
                                                                    logoDetails?.institution_id?.language?.map((el) => {
                                                                        return (
                                                                            <div
                                                                                style={{
                                                                                    border: lang === el
                                                                                        ? "2px solid #5FBB97"
                                                                                        : "2px solid #fff",
                                                                                }}
                                                                                onClick={() => changeLang(el)}
                                                                            >
                                                                                {el}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        : logoDetails?.language ?
                                                            <div className="form-group text-left">
                                                                <div className="elite-lang-select">
                                                                    {
                                                                        logoDetails?.language?.map((el) => {
                                                                            return (
                                                                                <div
                                                                                    style={{
                                                                                        border: lang === el
                                                                                            ? "2px solid #5FBB97"
                                                                                            : "2px solid #fff",
                                                                                    }}
                                                                                    onClick={() => changeLang(el)}
                                                                                >
                                                                                    {el}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div> :
                                                            null
                                                } */}

                                                <div className='position-relative'>
                                                    <button onClick={() => loginUser()} className="login-button">
                                                        {isLoading ? <BallBeat
                                                            color={'#fff'}
                                                            loading={true}
                                                        /> : "LOGIN"}
                                                    </button>

                                                </div>
                                                <h2 className='forgot-btn' onClick={resetPassword}>Forgot Password ?</h2>
                                            </div>
                                        </Fragment>
                                        :
                                        <div className="login-holder otp-holder">

                                            <h3>Reset your password.</h3>
                                            {
                                                otpEnable === false && emailOtpScreen ? <Fragment>
                                                    <p style={{ fontSize: 15 }}>{type === 'student' ? 'Please enter your registered Student Id.' : 'Please enter your registered Email address.'}</p>
                                                    <div className='radio-btns'>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="type" id="inlineRadio1" value="student" onChange={(e) => handleRadio(e.target.value)} />
                                                            <label className="form-check-label" htmlFor="inlineRadio1">
                                                                Student
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="type" id="inlineRadio2" value="faculty" onChange={(e) => handleRadio(e.target.value)} />
                                                            <label className="form-check-label" htmlFor="inlineRadio2">
                                                                Faculty
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mt-3 w-100">
                                                        <span>{responseStatus}</span>
                                                        <input type="email" className="form-control" disabled={type === ''} placeholder={type === 'student' ? 'Enter Student Id' : "Enter Email Address"} onChange={(e) => onOtpEmailChange(e)} onKeyPress={(e) => onOtpEmailChangeKeyPress(e)} value={otpEmail} />
                                                    </div>
                                                    <div className='error'>{emailError}</div>
                                                    <button onClick={() => handleOtpEmail()} className="primary-button position-relative mt-1">
                                                        {isOtpEmailLoading ? <BallBeat
                                                            color={'#fff'}
                                                            loading={true}
                                                        /> : "CONTINUE"}</button>

                                                </Fragment> :

                                                    resetPasswordComplete === false ?
                                                        <div className="otp-fields d-flex flex-column align-items-center">
                                                            <p>Please enter the code that is sent to your mail.</p>
                                                            <OtpInput
                                                                numInputs={6}
                                                                onChange={handleChange}
                                                                inputStyle="otp-input"
                                                                value={otp}
                                                                separator={<span>-</span>}
                                                            />

                                                            <div className="form-group" style={{ width: '100%' }}>
                                                                <input type="password" placeholder="Enter new password" className="form-control" aria-describedby="emailHelp" onChange={(e) => setNewPassword(e.target.value)} onKeyPress={(e) => onPasswordChange(e)} />
                                                                <input type="password" placeholder="Confirm new password" className="form-control" aria-describedby="emailHelp" onChange={(e) => setConfirmNewPassword(e.target.value)} onKeyPress={(e) => onPasswordChange(e)} />
                                                            </div>

                                                            <button disabled={isOtpEmailLoading === false ? false : true} onClick={() => handleOtpCheck1()} className="primary-button position-relative">
                                                                {isOtpEmailLoading ? <BallBeat
                                                                    color={'#fff'}
                                                                    loading={true}
                                                                /> : "Reset"}</button>
                                                        </div> : <div className="success-pasword-reset">
                                                            <p>Password has been successfully changed</p>
                                                            <button onClick={() => redirectTologin()} className="primary-button mt-2">Login</button>
                                                        </div>
                                            }

                                        </div>
                                }

                            </div> :
                            <div className="login-holder otp-holder">
                                <h3>Set your password</h3>
                                {
                                    <div className="otp-fields" style={{ width: window.innerWidth > 767 ? '100%' : '90%' }}>
                                        <p className='d-none'>Please enter the code that is sent to your mail.</p>
                                        {/* <div style={{ display: 'none' }}>
                                        <OtpInput
                                            numInputs={6}
                                            onChange={handleChange}
                                            inputStyle="otp-input"
                                            value={otp}
                                            separator={<span>-</span>}

                                        />
                                    </div> */}
                                        <input className='form-control d-none' onChange={(e) => handleChange(e.target.value)} style={{ height: 48 }} value={otp} />
                                        <div className="form-group mt-3">
                                            <input type="password" placeholder="Enter new password" className="form-control" aria-describedby="emailHelp" onChange={(e) => setNewPassword(e.target.value)} onKeyPress={(e) => onPasswordChange(e)} />
                                            <input type="password" placeholder="Confirm new password" className="form-control" aria-describedby="emailHelp" onChange={(e) => setConfirmNewPassword(e.target.value)} onKeyPress={(e) => onPasswordChange(e)} />
                                        </div>

                                        <button disabled={isOtpEmailLoading !== false} onClick={() => handleOtpCheck()} className="primary-button position-relative">
                                            {isOtpEmailLoading ? <BallBeat
                                                color={'#fff'}
                                                loading={true}
                                            /> : "Set"}
                                        </button>
                                    </div>
                                }

                            </div>
                        }
                        <div className='policy-link cursor-pointer'>
                            <p onClick={() => navigate('/privacy-policy')}>Privacy Policy</p>
                        </div>
                    </div>

                </div>
            }
        </Fragment>
    )
}


export default Login