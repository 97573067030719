const abilities = [
    { title: "Verbal Ability (VA)", description: `It assesses the child's knowledge of English word and their ability to abstract and generalize relations among words. The scores represent the occupational level up to  which one can aspire since there is a positive relationship in many occupations between the  level of responsibility of a job and the complexity of verbally phrased ideas to be comprehended` },
    { title: "Numerical ability (NA)", description: `It refers to the ability to manipulate numbers quickly and accurately. This ability is found high in individuals who are successful in mathematical,  scientific, and technical subjects. It is a predictor of occupations involving accounting, clerical,  engineering, technological, and data processing jobs` },
    { title: "Spatial ability (SA)", description: `This area assesses an individual's ability to perceive spatial patterns accurately and following the orientation of figures when their positioning is altered.  Individuals with high ability are successful in geometrical drawing drafting, vocational training and art design. It may help while dealing with building construction, engineering, commercial art, dress designing, die- making, and decoration.` },
    { title: "Closure ability (CA)", description: `It assesses the child's knowledge of English word and their ability to abstract and generalize relations among words. The scores represent the occupational level up to  which one can aspire since there is a positive relationship in many occupations between the  level of responsibility of a job and the complexity of verbally phrased ideas to be comprehended` },
    { title: 'Clerical ability (CL)', description: `It's a perceptual ability concerned with making rapid evaluations of visual stimuli. It's a speed and accuracy test. This ability is required in tasks involving record keeping, filling, taking inventories, dispatching coding and other similar jobs.` },
    { title: 'Reasoning ability (RA)', description: `It's the ability to apply the process of induction or to a reason from some specific information to a general principle. It also tests general intelligence, RA is  important to success in areas requiring logic, mathematical pursuit, computer programming,  engineering and technology, etc` },
    { title: 'Mechanical ability (MA)', description: `A high score on the ability means a good understanding of basic mechanical principles, simple machines, tools, carpentry, electrical, and automotive facts.  This ability is directly related to physical sciences or any other technical courses.` },
    { title: 'Psychomotor ability (PM)', description: `It refers to the precise movements requiring eye-hand coordination under high-speed conditions, it requires fine muscular dexterity. A good score  indicates success in assembly line work, Drafting, clerical, surgeon's job, etc,` },
    { title: 'Language Aptitude (LA)', description: `It refers to the ability to draw meaning from written words and use diem effectively. Language aptitude shows how well an individual understands words and their synonyms, spells words correcdy, identifies and understands die correct meaning of the given proverbs/idioms.` },
    { title: 'Abstract Reasoning (AR)', description: `It involves abilities such as thinking logically, managing time, and solving problems quickly and effectively. It requires to understand patterns, diagrams or designs and draw meaning from them. This ability reveals how well a student can reason,extract rules, find underlying logic in the pattern of symbols or shapes, identify correct answer among a set of possible options, complete sequence and find the odd one out. In AR sub-test, which is a non-verbal sub-test, each item consists of a set of figures/patterns which are in a certain sequence. The student is asked to find the next figure/pattern in the series.` },
    { title: 'Verbal Reasoning (VR)', description: `It is the ability to solve problems by understanding the meaning and ideas framed in words. Verbal reasoning measures how well an individual can apply reasoning related to words and draw correct meaning from the written information. In VR sub-test, the individual is expected to understand the relationship between paired words and apply it to other relationships.` },
    { title: 'Mechanical Reasoning (MR)', description: `It is the ability to apply reasoning in the practical environment using basic concepts in mechanics. This ability helps an individual to solve problems related to machines and engage in reasoning about the situation rather than simply applying die formulae. In MR sub-test, items are related to acceleration, pressure, energy transformation,work and power, levers, pulleys, screws, springs, tools, etc.` },
    { title: 'Numerical Aptitude (NA)', description: `It is the ability to perform mathematical operations quickly and correctly. Numerical aptitude includes numerical relationships and problem solving related to numbers. NA sub-test involves primary arithmetic operations (like addition, subtraction,multiplication, and division) and other mathematical operations (like ratio, percentage,square and square root).` },
    { title: 'Spatial Aptitude (SA)', description: `It is the ability to imagine an object in space and decide how it will look like when rotated in a given direction. In SA sub-test, the student is asked to identify how the figure will look like when seen through a mirror or rotated or when folded in a particular way.` },
    { title: 'Perceptual Aptitude (PA)', description: `It involves comparing visual information like letters, numbers or combinations of letters/numbers, quickly and accurately. In PA sub-test, student is asked to compare the paired groups of letters or numbers or combination of letters-numbers and identify the similarity or difference.` }
]


export const getDescByTitle = (title) => {
    return abilities?.filter(el => el.title === title)[0]?.description
}